import React, { useState, useEffect, useRef } from 'react';
import './App.css';

import { UserPermissionsProvider } from './context/UserPermissionsContext';

import 'mdb-react-ui-kit/dist/css/mdb.dark.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// Install correct version react-router-dom
// npm install react-router-dom@5.2.0
import axios from 'axios';

// import all faculties from their folder
import { Header_roaldvgs, Navbar_roaldvgs, Docs_roaldvgs, Links_roaldvgs } from './faculty/portal/container';

// Import components
import Admin from './admin/Admin';
import Login from './admin/Login';
import Hero from './faculty/Hero';
import Configure from './admin/configure/Configure';
import ModalPanel from './admin/modalPanel/ModalPanel';
import Register from './admin/roaldvgs/register/Register';
import FacultyRegistration from './admin/facultyRegistration/FacultyRegistration';
import FacultySettings from './admin/facultySettings/FacultySettings.jsx';
import ProfilePage from './admin/roaldvgs/profilePage/ProfilePage';
import UserList from './admin/roaldvgs/userList/UserList';
import NoAccess from './admin/noAccess/NoAccess';
import AdminChangelog from './admin/adminChangelog/AdminChangelog'

// Import Stripe components
import Checkout from './admin/stripe/Checkout';
import Portal from './admin/stripe/Portal';

const App = () => {
    const [facultyNames, setFacultyNames] = useState([]);
    const selectedFaculty = localStorage.getItem("selectedFaculty");

    // Fetch faculty names
    useEffect(() => {
        axios.get('/api/get_faculties.php')
            .then((response) => {
                setFacultyNames(response.data.map((faculty) => faculty.name));
            })
            .catch((error) => {
                console.error('Error fetching faculty names:', error);
            });
    }, []);

    useEffect(() => {
        axios.get(`/api/getFacultySettings.php?facultyName=${selectedFaculty}`)
            .then((response) => {
                if (response.data && response.data.refreshTimer) {
                    setRefreshTimer(parseInt(response.data.refreshTimer, 10));
                    console.log("Refresh timer after parse:", response.data.refreshTimer)
                }
            })
            .catch((error) => {
                console.error('Error fetching refresh timer:', error);
            });
    }, [selectedFaculty]);

    return (
        <UserPermissionsProvider>
            <Router>
                <div className="App">
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => (
                                <div>
                                    <Hero />
                                </div>
                            )}
                        />
                        <Route exact path="/Admin/:faculty" render={(props) => {
                            console.log(props.match.params.faculty);
                            return <Admin />;
                        }} />
                        <Route path="/Login" component={() => <Login />} />
                        <Route path="/Header" component={() => <Header_roaldvgs />} />
                        <Route path="/Configure" component={() => <Configure />} />
                        <Route path="/Register" component={() => <Register />} />
                        <Route path="/ModalPanel" component={() => <ModalPanel />} />
                        <Route path="/FacultyRegistration" component={() => <FacultyRegistration />} />
                        <Route path="/profile/:username" component={() => <ProfilePage />} />
                        <Route path="/UserList" component={() => <UserList />} />
                        <Route path="/Changelog" component={() => <AdminChangelog />} />
                        <Route path="/assets" component={() => <NoAccess />} />
                        <Route path="/FacultySettings" component={() => <FacultySettings />} />
                        <Route path="/checkout" component={Checkout} />
                        <Route path="/portal" component={Portal} />
                        {facultyNames.map((facultyName) => (
                            <Route
                                key={facultyName}
                                path={`/${facultyName}`}
                                render={() => (
                                    <div>
                                        <Header_roaldvgs />
                                        <Docs_roaldvgs />
                                        <Links_roaldvgs />
                                    </div>
                                )}
                            />
                        ))}
                    </Switch>
                </div>
            </Router>
        </UserPermissionsProvider>
    );
};

export default App;
