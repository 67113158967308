import React, { useState, useEffect } from "react";
import { Rotate as Hamburger } from "hamburger-react";
import logo from "../../../../assets/logo.png";
import "./Navbar.css";
import axios from "axios";
import { MDBTypography } from "mdb-react-ui-kit";

const Menu = () => (
  <>
    <p>
      <a href="#home">Hjem</a>
    </p>
    <p>
      <a href="#docs">Veiledninger</a>
    </p>
    <p>
      <a href="#feedback">Tilbakemeldinger</a>
    </p>
    <p>
      <a href="#features">Smarte linker</a>
    </p>
  </>
);

const Navbar = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);


  
  // Fetch all faculty data from databsae

  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [latestNews, setLatestNews] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const facultyName = getFacultyNameFromPath();

    if (facultyName) {
      // Fetch data
      axios
        .get(`/api/get_data.php?facultyName=${facultyName}`)
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching DATA:", error);
        });

      // Fetch email
      axios
        .get(`/api/get_email.php?facultyName=${facultyName}`)
        .then((response) => {
          setEmail(response.data.email);
        })
        .catch((error) => {
          console.error("Error fetching Email:", error);
        });

      // Fetch latestNews
      axios
        .get(`/api/get_latestnews.php?facultyName=${facultyName}`)
        .then((response) => {
          setLatestNews(response.data.inputValue); // Use response.data.inputValue
        })
        .catch((error) => {
          console.error("Error fetching latestNews:", error);
        });

      // Fetch phone
      axios
        .get(`/api/get_phone.php?facultyName=${facultyName}`)
        .then((response) => {
          setPhone(response.data.phone);
        })
        .catch((error) => {
          console.error("Error fetching phone:", error);
        });
    }
  }, []);

  const getFacultyNameFromPath = () => {
    const path = window.location.pathname;
    const pathParts = path.split("/");
    return pathParts.length > 1 ? pathParts[pathParts.length - 1] : null;
  };

  // END OF = Fetch all faculty data from database


  // Fetch the active announcement for the current faculty

    const [activeAnnouncement, setActiveAnnouncement] = useState({
      text: "",
      title: "",
      color: "",
    });

    useEffect(() => {
      const facultyName = getFacultyNameFromPath();

      if (facultyName) {
        axios
          .get(`/api/fetch_active_announcement.php?facultyName=${facultyName}`)
          .then((response) => {
            if (response.data.success) {
              setActiveAnnouncement(response.data.announcement);
            } else {
              console.error(
                "Error fetching active announcement:",
                response.data.message
              );
            }
          })
          .catch((error) => {
            console.error("Error fetching active announcement:", error);
          });
      }
    }, []);

  return (
    <div className="navbar-wrapper">
      <div className="viken__navbar-latest_news ps-1 pe-1">
        <MDBTypography tag='h3'>SISTE NYTT :</MDBTypography>
        {activeAnnouncement.text && (
            <p>{activeAnnouncement.text}</p>
        )}
      </div>
      {/* <div className="viken__navbar">
        <div className="viken__navbar-links">
          <div className="viken__navbar-links_logo">
            <img src={logo} alt="logo"></img>
          </div>
          <div className="viken__navbar-links_container">
            <Menu />
          </div>
        </div>
        <div className="viken__navbar-menu">
          <Hamburger
            onToggle={(toggled) => {
              if (toggled) {
                setToggleMenu(true);
              } else {
                setToggleMenu(false);
              }
            }}
          />
          {toggleMenu && (
            <div className="viken__navbar-menu_container scale-up-center">
              <div className="viken__navbar-menu_container-links">
                <Menu />
                <div className="viken__navbar-menu_container-links-sign">
                  <p>Sign in</p>
                  <button type="button">Sign up</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default Navbar;
