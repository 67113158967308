import React, { useState, useEffect } from "react";
import axios from "axios";
import QRCode from "qrcode.react";
import "./Header.css";
import { SlClock, SlScreenSmartphone, SlEnvolope } from "react-icons/sl";
import { MDBBtn, MDBCol, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import { Navbar_roaldvgs } from "..";

const Header = () => {
  useEffect(() => {
    // Add the fixed-position class on component mount
    document.body.classList.add("fixed-position");

    // Clean up function to remove the class on component unmount
    return () => {
      document.body.classList.remove("fixed-position");
    };
  }, []);

  const [headerClass, setHeaderClass] = useState("");

  // Fetch all faculty data from databsae

  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [latestNews, setLatestNews] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const facultyName = getFacultyNameFromPath();

    if (facultyName) {
      // Fetch data
      axios
        .get(`/api/get_data.php?facultyName=${facultyName}`)
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching DATA:", error);
        });

      // Fetch email
      axios
        .get(`/api/get_email.php?facultyName=${facultyName}`)
        .then((response) => {
          setEmail(response.data.email);
        })
        .catch((error) => {
          console.error("Error fetching Email:", error);
        });

      // Fetch latestNews
      axios
        .get(`/api/get_latestnews.php?facultyName=${facultyName}`)
        .then((response) => {
          setLatestNews(response.data.inputValue); // Use response.data.inputValue
        })
        .catch((error) => {
          console.error("Error fetching latestNews:", error);
        });

      // Fetch phone
      axios
        .get(`/api/get_phone.php?facultyName=${facultyName}`)
        .then((response) => {
          setPhone(response.data.phone);
        })
        .catch((error) => {
          console.error("Error fetching phone:", error);
        });
    }
  }, []);

  const getFacultyNameFromPath = () => {
    const path = window.location.pathname;
    const pathParts = path.split("/");
    return pathParts.length > 1 ? pathParts[pathParts.length - 1] : null;
  };

  // END OF = Fetch all faculty data from database

  const handleClick = () => {
    setHeaderClass("slide-out-blurred-top");
    document.body.classList.remove("fixed-position");
  };

  const [facultyBackground, setFacultyBackground] = useState(""); // State for facultyBackground
  const facultyName = getFacultyNameFromPath();
  useEffect(() => {
    // Fetch facultyBackground data
    axios
      .get(`/api/getFacultySettings.php?facultyName=${facultyName}`)
      .then((response) => {
        const background = response.data.facultyBackground
          ? `url(${response.data.facultyBackground})`
          : "#fff";
        setFacultyBackground(background);
      })
      .catch((error) => {
        console.error("Error fetching faculty settings:", error);
        setFacultyBackground("#fff"); // Set to default if there's an error
      });
  }, [facultyName]);


  // Fetch jokes :)

  function DadJoke() {
    const [joke, setJoke] = useState("");

    useEffect(() => {
      fetch("https://icanhazdadjoke.com/", {
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => setJoke(data.joke))
        .catch((error) => console.error("Error fetching the joke:", error));
    }, []);

    return (
      <div className="w-100 my-2 text-center">
        <p className="joke_text">Vits: {joke || "Laster vits..."}</p>
      </div>
    );
  }


  // Fetch the active announcement for the current faculty

  const [activeAnnouncement, setActiveAnnouncement] = useState({
    text: "",
    title: "",
    color: "",
  });

  useEffect(() => {
    const facultyName = getFacultyNameFromPath();

    if (facultyName) {
      axios
        .get(`/api/fetch_active_announcement.php?facultyName=${facultyName}`)
        .then((response) => {
          if (response.data.success) {
            setActiveAnnouncement(response.data.announcement);
          } else {
            console.error(
              "Error fetching active announcement:",
              response.data.message
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching active announcement:", error);
        });
    }
  }, []);



  return (
    <>
      <Navbar_roaldvgs />
      <div
        className={`viken__header-wrapper section__padding fixed-position ${headerClass}`}
      >
        {/* <video src={viken_bg} autoPlay loop muted></video> */}
        <div
          className="viken__header-content"
          style={{
            backgroundImage: facultyBackground.includes("url")
              ? `linear-gradient(45deg, rgba(194, 194, 194, 0.8) 100%, transparent), ${facultyBackground}`
              : facultyBackground,
          }}
        >
          <DadJoke />
          <MDBRow className="h-100">
            <MDBCol md='7' lg='8' xl='8' xxl='6' className="flex-fill">
              <div className="viken__header p-4">
                <div className="viken__header-container text-start d-flex align-items-start">
                  <MDBTypography tag='p' className="display-1">
                    Velkommen til Selvbetjeningsportal for
                  </MDBTypography>
                  <h1 className="display-1">{data !== null ? <p>{data}</p> : <p>(Henter navn...)</p>}</h1>
                  <div className="w-100 p-5 bg-primary flex-column rounded my-5">
                    <MDBTypography tag='h3' className="text-light">TIL INFO:</MDBTypography>
                    {activeAnnouncement.text && (
                      <p className="text-light fs-3">{activeAnnouncement.text}</p>
                    )}
                  </div>
                  <div className="button_start-wrapper w-100 mt-5">
                    <div className="button_start-text"></div>
                    <div className="button_start">
                      <button className="scroll-to-docs rounded" onClick={handleClick}>
                        Start
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </MDBCol>

            <MDBCol md='7' lg='4' xl='4' xxl='3' className="flex-fill">
              <div className="viken__header-stats_icons-text-list" style={{ flexDirection: 'column' }}>
                <div className="viken__header-stats_icons-text card m-2">
                  <div className="viken__header-stats_icon">
                    <SlClock />
                  </div>
                  <h4>Våre åpningstider</h4>
                  <p>08:00 - 15:30</p>
                </div>
                <div className="viken__header-stats_icons-text card m-2">
                  <div className="viken__header-stats_icon">
                    <SlEnvolope />
                  </div>
                  <h4>{email}</h4>
                  <div className="QR-wrapper">
                    <QRCode
                      className="QR_code"
                      value={email}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                </div>
                <div className="viken__header-stats_icons-text card m-2">
                  <div className="viken__header-stats_icon">
                    <SlScreenSmartphone />
                  </div>
                  <h4>{phone}</h4>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </div>
    </>
  );
};

export default Header;
