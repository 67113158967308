import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import "./Login.css";
import axios from "axios";
import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardHeader,
  MDBCol,
  MDBCardBody,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBTabsContent,
  MDBIcon,
  MDBCheckbox,
  MDBInput,
  MDBBtn,
  MDBTextArea,
  MDBAnimation,
  MDBAlert,
  MDBSelect
} from "mdb-react-ui-kit";

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useUserPermissions } from '../context/UserPermissionsContext';

// Images
import customLogo from '../assets/MadeByDencker.png';
import loginDecor from '../assets/loginDecor.png';

const Login = (props) => {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [faculties, setFaculties] = useState([]);
  const [selectedFaculty, setSelectedFaculties] = useState("");

  // Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyA2hywDnWut6elombYMNKDnn08Jf1-6B9A",
    authDomain: "dencker-abb01.firebaseapp.com",
    projectId: "dencker-abb01",
    storageBucket: "dencker-abb01.appspot.com",
    messagingSenderId: "924075531945",
    appId: "1:924075531945:web:33927bad96ead4eb1b53b8",
    measurementId: "G-7JB32TXKNB",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  // Fetch user data
  const fetchUserData = async (firebaseUserId) => {
    try {
      const response = await axios.post("/api/fetch_user_data.php", {
        firebaseUserId,
      });
      const userData = response.data;
      if (userData.success && userData.user.faculties) {
        // Convert faculties object to array of faculty names
        const facultyNames = Object.values(userData.user.faculties);
        // Remove duplicates
        const uniqueFaculties = Array.from(new Set(facultyNames));
        setFaculties(
          uniqueFaculties.map((facultyName) => ({
            name: facultyName,
            value: facultyName,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("Failed to fetch user data.");
    }
  };

  const facultyOptions = faculties.map((faculty) => ({
    text: faculty.name.toString(), // Ensure it's a string
    value: faculty.value.toString(), // Ensure it's a string
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        username,
        password
      );
      const firebaseUserId = userCredential.user.uid; // Get the Firebase user ID
      localStorage.setItem("firebaseUserId", firebaseUserId); // Store the Firebase user ID in localStorage
      setLoggedIn(true);
      fetchUserData(firebaseUserId); // Pass the Firebase user ID instead of the JWT token
    } catch (error) {
      console.error("Firebase sign-in error:", error);
      setError('Feil brukernavn/passord.');
    }
  };

  const handleFacultySelection = async (valObj) => {
    const faculty = valObj.value; // Extract the 'value' property from the object

    if (!faculty) {
      console.error("Selected faculty ID is undefined.");
      return;
    }

    try {
      const firebaseUserId = localStorage.getItem("firebaseUserId");
      if (!firebaseUserId) {
        console.error("Firebase User ID not found.");
        return;
      }

      // Make an API call to set the active faculty
      await axios.post("/api/set_active_faculty.php", {
        firebaseUserId,
        activeFaculty: faculty,
      });

      // Update local storage and redirect
      localStorage.setItem("activeFaculty", faculty);
      window.location.href = `/Admin/${faculty}`;
      localStorage.setItem("selectedFaculty", faculty);
      localStorage.setItem("username", username);

      // Optionally: Update permissions in context here if needed
    } catch (error) {
      console.error("Error setting active faculty:", error);
    }
  };

  // Handle inactivity timeout
  const inactivityTimeout = useRef(null);

  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeout.current);
    inactivityTimeout.current = setTimeout(() => {
      const facultyURL = localStorage.getItem('facultyURL');
      if (facultyURL) {
        const url = new URL(facultyURL);
        history.push(url.pathname + url.search + url.hash); // Use the correct path for redirection
      } else {
        history.push("/"); // Fallback to root if no URL is found
      }
    }, 1 * 60 * 1000); // 1 minute
  };

  useEffect(() => {
    const handleActivity = () => resetInactivityTimeout();

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("click", handleActivity);

    resetInactivityTimeout();

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("click", handleActivity);
      clearTimeout(inactivityTimeout.current);
    };
  }, []);


  return (
    <div className="viken__login-wrapper">
      <div className="viken__login-container">
        <div className="viken__login-form">
          <div className="viken__login-form_text">
            <MDBContainer fluid className="mt-5">
              <section className="text-center text-lg-start">
                <div className="container py-4">
                  <MDBRow className="g-0 align-items-center">
                    <MDBCol lg="6" className="mb-5 mb-lg-0 m-auto" style={{ zIndex: "999" }}>
                      <div
                        className="card cascading-right"
                        style={{
                          background: "hsla(0, 0%, 100%, 0.55)",
                          backdropFilter: "blur(30px)",
                        }}
                      >
                        <div className="card-body d-flex flex-column align-items-center p-5 shadow-5 text-center">
                          <img
                            src={customLogo}
                            className="img-fluid"
                            alt=""
                            style={{ height: "100px" }}
                          />
                          <MDBAnimation
                            start="onLoad"
                            animation="fade-in-up"
                            duration={500}
                          >
                            <h2 className="display-6 mb-4">Login</h2>
                          </MDBAnimation>
                          {!loggedIn && (
                            <form onSubmit={(e) => handleSubmit(e, faculties)}>
                              <div className="container-fluid">
                                <MDBAnimation
                                  start="onLoad"
                                  animation="fade-in-left"
                                  duration={800}
                                  delay={400}
                                >
                                  <MDBInput
                                    className="mb-4"
                                    type="text"
                                    label="E-post"
                                    name="username"
                                    value={username}
                                    onChange={(e) =>
                                      setUsername(e.target.value)
                                    }
                                  />
                                </MDBAnimation>
                                <MDBAnimation
                                  start="onLoad"
                                  animation="fade-in-left"
                                  duration={800}
                                  delay={800}
                                >
                                  <MDBInput
                                    className="mb-4"
                                    label="Passord"
                                    type="password"
                                    name="password"
                                    placeholder="********"
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                </MDBAnimation>
                              </div>
                              <MDBAnimation
                                start="onLoad"
                                animation="fade-in-left"
                                duration={500}
                                delay={1200}
                              >
                                <MDBBtn type="submit" block className="mb-4">
                                  Login
                                </MDBBtn>
                              </MDBAnimation>
                              {error && (
                                <MDBAlert open className="w-100" color="danger">
                                  <i className="fas fa-times-circle me-3"></i>
                                  {error}
                                </MDBAlert>
                              )}
                            </form>
                          )}
                          {loggedIn && faculties.length > 0 && (
                            <MDBSelect
                              search
                              data={facultyOptions}
                              label="Velg en fakultet"
                              selected={selectedFaculty} // Ensure this is correctly managed
                              onValueChange={(val) =>
                                handleFacultySelection(val)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </MDBCol>

                    <MDBCol lg="6" className="mb-5 mb-lg-0 position-absolute">
                      <img
                        src={loginDecor}
                        className="w-100 rounded-4 shadow-4"
                        alt=""
                        style={{ marginLeft: "-30px" }}
                      />
                    </MDBCol>
                  </MDBRow>
                </div>
              </section>
            </MDBContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
